import '@mantine/core/styles.css'
import '@mantine/tiptap/styles.css'
import {
	json,
	type LoaderFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
import {
	Form,
	Link,
	Links,
	Meta,
	NavLink,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { EpicProgress } from './components/progress-bar.tsx'
import { useToast } from './components/toaster.tsx'
import { Button } from './components/ui/button.tsx'
import { Icon, href as iconsHref } from './components/ui/icon.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import { useTheme } from './routes/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { type Theme, getTheme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { useOptionalUser } from './utils/user.ts'
import { getAggregatedUserCredits } from './utils/credits.server.ts'
import {
	Avatar,
	Burger,
	ColorSchemeScript,
	Group,
	MantineProvider,
	Stack,
	Text,
	UnstyledButton,
} from '@mantine/core'
import { AppShell } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Coins, Menu, Search } from 'lucide-react'
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from './components/ui/sheet.tsx'
import { generatePresignedUrl } from './utils/s3.server.ts'
import { SearchBar } from './components/search-bar.tsx'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Smartpolice' : 'Error | Smartpolice' },
		{ name: 'description', content: `Your own captain's log` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							email: true,
							isAdmin: true,
							image: { select: { id: true, filename: true, url: true } },
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	let creditsRemaining = 0
	if (user) {
		creditsRemaining = await getAggregatedUserCredits(user.id)
		if (user.image) {
			const signedUrl = await generatePresignedUrl(
				`profile-photo/${user.image.filename}`,
			)
			user.image.url = signedUrl
		}
	}

	return json(
		{
			creditsRemaining,
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

function Document({
	children,
	nonce,
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	return (
		<html lang="en" className={`h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
				<ColorSchemeScript />
			</head>
			<body className="bg-gray-100 text-foreground">
				<MantineProvider>{children}</MantineProvider>
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const [opened, { toggle }] = useDisclosure()
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const user = useOptionalUser()
	const theme = useTheme()
	const matches = useMatches()
	const isOnLandingPage = matches.find(
		(m) => m.id === 'routes/_marketing+/index',
	)
	const isOnPrivacyPage = matches.find(
		(m) => m.id === 'routes/_marketing+/privacy',
	)
	// const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />
	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
	useToast(data.toast)

	if (!user || isOnLandingPage || isOnPrivacyPage) {
		return (
			<Document
				nonce={nonce}
				theme={theme}
				allowIndexing={allowIndexing}
				env={data.ENV}
			>
				<div className="flex min-h-screen w-full flex-col justify-between">
					<header className="container sticky top-0 z-40 flex h-16 items-center gap-4 px-4 md:px-6">
						<nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
							<Logo />
							<Link
								to="/#features"
								className="flex items-center text-lg font-medium text-foreground/60 transition-colors hover:text-foreground/80 sm:text-sm"
							>
								Features
							</Link>
							<Link
								to="/#pricing"
								className="flex items-center text-lg font-medium text-foreground/60 transition-colors hover:text-foreground/80 sm:text-sm"
							>
								Pricing
							</Link>
						</nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button
									variant="outline"
									size="icon"
									className="shrink-0 md:hidden"
								>
									<Menu className="h-5 w-5" />
									<span className="sr-only">Toggle navigation menu</span>
								</Button>
							</SheetTrigger>
							<SheetContent side="left">
								<SheetHeader className="sr-only">
									<SheetTitle>Nav</SheetTitle>
									<SheetDescription>
										Side navigation for the main site
									</SheetDescription>
								</SheetHeader>
								<nav className="grid gap-6 text-lg font-medium">
									<Link
										to="/#features"
										className="flex items-center text-lg font-medium text-foreground/60 transition-colors hover:text-foreground/80 sm:text-sm"
									>
										Features
									</Link>
									<Link
										to="/#pricing"
										className="flex items-center text-lg font-medium text-foreground/60 transition-colors hover:text-foreground/80 sm:text-sm"
									>
										Pricing
									</Link>
								</nav>
							</SheetContent>
						</Sheet>
						<div className="flex-1 sm:block"></div>
						<div className="flex items-center gap-10">
							{user ? (
								<Button asChild variant="default" size="lg">
									<Link to="/dashboard">Dashboard</Link>
								</Button>
							) : (
								<Button asChild variant="default" size="lg">
									<Link to="/login">Log in</Link>
								</Button>
							)}
						</div>
					</header>

					<div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
						<Outlet />
					</div>

					<div className="container flex justify-between pb-5">
						<Logo />

						<a href="https://docs.google.com/document/d/1EqqQa8Vo53gnkSjAWlw7MbY_p-P0_BbZhznBMZS-a7Q/edit?usp=sharing">
							Privacy Policy
						</a>
					</div>
				</div>
			</Document>
		)
	}

	return (
		<Document
			nonce={nonce}
			theme={theme}
			allowIndexing={allowIndexing}
			env={data.ENV}
		>
			<AppShell
				header={{ height: 60 }}
				navbar={{
					width: 300,
					breakpoint: 'sm',
					collapsed: { mobile: !opened },
				}}
				padding="md"
			>
				<AppShell.Header>
					<Group h="100%" px="md">
						<Burger
							opened={opened}
							onClick={toggle}
							hiddenFrom="sm"
							size="sm"
						/>
						<Logo />
						<div className="flex-1">
							<div className="mx-auto max-w-xl">
								<SearchBar status="idle" />
							</div>
						</div>
						<Group className="text-gray-500">
							<div className="flex gap-1">
								<Coins />
								<Text>{data.creditsRemaining} credits</Text>
							</div>
							<Button asChild size="icon">
								<Link to="/cases/new">
									<Icon name="plus" size="sm" />
								</Link>
							</Button>
						</Group>
					</Group>
				</AppShell.Header>
				<AppShell.Navbar p="md">
					<Stack h={300} gap="lg" className="flex-1">
						<NavLink
							to="/dashboard"
							className={({ isActive }) =>
								`flex gap-2 rounded-sm p-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`
							}
						>
							<Icon name="dashboard"></Icon>
							Dashboard
						</NavLink>
						<NavLink
							to="/chats"
							className={({ isActive }) =>
								`flex gap-2 rounded-sm p-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`
							}
						>
							<Icon name="chat-bubble"></Icon>
							Chats
						</NavLink>
						<NavLink
							to="/cases"
							className={({ isActive }) =>
								`flex gap-2 rounded-sm p-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`
							}
						>
							<Icon name="file-text"></Icon>
							Cases
						</NavLink>
						{user.isAdmin && (
							<NavLink
								to="/admin"
								className={({ isActive }) =>
									`flex gap-2 rounded-sm p-2 hover:bg-accent ${isActive ? 'bg-accent' : ''}`
								}
							>
								<Icon name="gear"></Icon>
								Admin
							</NavLink>
						)}
					</Stack>
					<Form
						action="/logout"
						method="POST"
						className="text-md p-2 font-semibold text-gray-500"
					>
						<Icon className="text-body-md" name="exit">
							<button type="submit">Logout</button>
						</Icon>
					</Form>
					<div className="border-t">
						<Link to="/settings/profile">
							<UnstyledButton
								className="w-full hover:bg-secondary"
								variant="ghost"
							>
								<Group className="p-4 px-2">
									<Avatar src={user.image?.url} radius="xl" />
									<div style={{ flex: 1 }}>
										<Text size="sm" fw={500}>
											{user?.name ?? user?.username}
										</Text>

										<Text c="dimmed" size="xs">
											{user?.email}
										</Text>
									</div>
									<Icon name="chevron-right" size="sm" />
								</Group>
							</UnstyledButton>
						</Link>
					</div>
				</AppShell.Navbar>
				<AppShell.Main className="flex flex-col">
					<Outlet />
				</AppShell.Main>
			</AppShell>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>
	)
}

function Logo() {
	return (
		<Link
			to="/"
			className="group flex items-center gap-1 font-bold leading-snug"
		>
			<img src="/img/logo.jpeg" alt="" className="h-8 w-8" />
			{/* <span className="p-2 font-bold">Smartpolice</span> */}
		</Link>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
